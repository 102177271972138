import React from 'react';
import { Typography, Box, Tabs, Tab, useMediaQuery, useTheme, styled, Button } from '@mui/material';
import { VerticalTabComponent } from '../../api/content';
import { ContentSection } from './ContentSection';
import { RichText } from './RichText';

export interface VerticalTabsProps {
  title: React.ReactNode;
  tabs: Array<VerticalTabComponent>;
}

export function VerticalTabs({ title, tabs }: VerticalTabsProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, tabIndex: number) => {
    setTabIndex(tabIndex);
  };
  return (
    <ContentSection bannerBGColor="primary.main" padding={3} justify="center">
      <Typography variant="h3" color="common.white" textAlign="center">
        {title}
      </Typography>
      <Box
        sx={{ flexGrow: 1, display: 'flex' }}
        flexDirection={isSmallScreen ? 'column' : 'row'}
        p={3}
      >
        <StyledTabs
          orientation={isSmallScreen ? 'horizontal' : 'vertical'}
          variant="scrollable"
          value={tabIndex}
          onChange={handleChange}
          aria-label="Vertical tabs"
        >
          {tabs.map((tab, index) => (
            <StyledTab key={index} label={tab.title} {...a11yProps(index)} />
          ))}
        </StyledTabs>
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={tabIndex} index={index}>
            <TabPanelRichText content={tab.copy} />
            {tab.link && (
              <Button variant={'greyButton'} href={tab.link}>
                {tab.linkCopy}
              </Button>
            )}
          </TabPanel>
        ))}
      </Box>
    </ContentSection>
  );
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderRight: 1,
  borderColor: 'divider',
  '& .MuiTabs-indicator': { backgroundColor: theme.palette.common.white },
  backgroundColor: theme.palette.transparent.total,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.transparent.white,
  '&.Mui-selected': { color: theme.palette.common.white },
  alignItems: 'start',
  textAlign: 'start',
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '.75rem 1rem', '& p, h2, h3, h4, h5, h6': { marginTop: 0 } }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const TabPanelRichText = styled(RichText)(({ theme }) => ({
  color: theme.palette.common.white,
}));

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
