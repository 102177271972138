import { RichTextContent } from '../../api/content';
import { Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

export interface RichTextProps {
  className?: string;
  content: RichTextContent;
}

export function RichText({ className, content }: RichTextProps) {
  return (
    <ReactMarkdown
      className={className}
      components={{
        h1: ({ children }) => <Typography variant="h1">{children}</Typography>,
        h2: ({ children }) => <Typography variant="h2">{children}</Typography>,
        h3: ({ children }) => <Typography variant="h3">{children}</Typography>,
        h4: ({ children }) => <Typography variant="h4">{children}</Typography>,
        h5: ({ children }) => <Typography variant="h5">{children}</Typography>,
        h6: ({ children }) => <Typography variant="h6">{children}</Typography>,
        p: ({ children }) => <Typography variant="body1">{children}</Typography>,
      }}
    >
      {content}
    </ReactMarkdown>
  );
}
