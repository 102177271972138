import { ApiClient, EntityItem, Page } from '../api/content';
import { createPageQuery, unwrap } from './factory';

interface GetInvolvedPage extends Page {}

function getGetInvolvedPage() {
  return ApiClient.get<EntityItem<GetInvolvedPage>>('/get-involved', {
    params: {
      populate: 'deep',
    },
  });
}

export const {
  query: GetInvolvedPageQuery,
  useQuery: useGetInvolvedPageQuery,
  prefetch: prefetchGetInvolvedPage,
} = createPageQuery({
  queryKey: ['get-involved'],
  queryFn: unwrap(getGetInvolvedPage),
});
