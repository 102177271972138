import { Alert, LinearProgress, Stack } from '@mui/material';
import { DynamicComponentTypes } from '../../api/content';
import { FlipCardSection } from './FlipCardSection';
import { VerticalTabs } from './VerticalTabs';
import { VisualContent } from './VisualContent';
import { CircleBgGrid } from './CircleBgGrid';
import { StatsSection } from './StatsSection';
import { PageFooterSection } from './PageFooterSection';
import { withBoundary } from '../withBoundary';
import { useMemo } from 'react';
import { NewsEventRecap } from './NewsEventRecap';
import { NewsImpactStory } from './NewsImpactStory';

export interface DynamicZoneProps {
  components: Array<DynamicComponentTypes>;
  spacing?: number;
}

export function DynamicZone({
  components,
  spacing = 2,
}: {
  components: Array<DynamicComponentTypes>;
  spacing?: number;
}) {
  const Renderer = useMemo(
    () =>
      // component boundary must be initialized lazily (although it's unclear why, other than an error referencing jsxDEV on initial render)
      withBoundary(ComponentRenderer, {
        // define boundary fallbacks so that any render issue with one component in the zone does not affect the others
        errorFallback: <Alert severity="error">Content rendering failed.</Alert>,
        suspenseFallback: <LinearProgress />,
      }),
    []
  );

  return (
    <Stack spacing={spacing}>
      {components.map((component, index) => {
        // the component.id property is non-unique and should not be used as a key
        return <Renderer key={index} {...component} />;
      })}
    </Stack>
  );
}

export function ComponentRenderer(component: DynamicComponentTypes) {
  switch (component.__component) {
    case 'shared.grapes-content':
    case 'news.press-release':
      return <VisualContent data={component.content} />;
    case 'shared.vertical-tabs':
      return <VerticalTabs title={component.title} tabs={component.Tab} />;
    case 'shared.stat-section':
      return <StatsSection {...component} />;
    case 'shared.flip-card-section':
      return <FlipCardSection {...component} />;
    case 'shared.circle-background-row':
      return <CircleBgGrid {...component} />;
    case 'shared.page-footer-section':
      return <PageFooterSection content={component.page_footer.data?.attributes.content} />;
    case 'news.event-recap':
      return <NewsEventRecap gallery={component.eventGallery} content={component.content} />;
    case 'news.impact-story':
      return (
        <NewsImpactStory
          content={component.content}
          calloutStatistic={component.calloutStat}
          calloutCopy={component.calloutCopy}
        />
      );
    default:
      console.warn('Unknown dynamic zone component type.', component);
      return null;
  }
}
