import React, { useMemo } from 'react';
import { styled, Grid, Button } from '@mui/material';
import {
  RelatedEntity,
  RichTextContent,
  File,
  DynamicCircleBackgroundSectionComponent,
} from '../../api/content';
import { formatMediaUrl, isFile } from '../utils';
import { RichText } from './RichText';

export interface CircleBgProps {
  leftText: RichTextContent;
  leftBackground: RelatedEntity<File>;
  rightText: RichTextContent;
  rightBackground: RelatedEntity<File>;
  leftSideCircle: DynamicCircleBackgroundSectionComponent['leftSideCircle'];
  rightSideCircle: DynamicCircleBackgroundSectionComponent['rightSideCircle'];
  leftLink: string;
  leftLinkCopy: string;
  rightLink: string;
  rightLinkCopy: string;
}

const BannerRichText = styled(RichText)(({ theme }) => ({
  color: theme.palette.common.white,
  '& p': {
    fontSize: '1.25rem',
  },
  ...theme.typography,
}));

const leftBGSize: Record<CircleBgProps['leftSideCircle'], string> = {
  No: 'cover',
  Yes: 'contain',
};

const rightBGSize: Record<CircleBgProps['rightSideCircle'], string> = {
  No: 'cover',
  Yes: 'contain',
};

const GridItem = styled(Grid)(({ theme }) => ({
  color: theme.palette.common.white,
  aspectRatio: '1/1',
  minHeight: '300px',
  backgroundColor: theme.palette.primary.main,
  padding: '4rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  marginTop: -16,
  flexDirection: 'column',
}));

export function CircleBgGrid({
  leftText,
  leftBackground,
  rightText,
  rightBackground,
  leftSideCircle,
  rightSideCircle,
  rightLink,
  rightLinkCopy,
  leftLink,
  leftLinkCopy,
}: CircleBgProps) {
  const leftSize = leftBGSize[leftSideCircle ?? 'no'];
  const rightSize = rightBGSize[rightSideCircle ?? 'no'];
  const leftBG = useMemo(() => {
    const cellBackground = leftBackground.data?.attributes;
    const bannerImageURL = isFile(cellBackground) ? formatMediaUrl(cellBackground) : cellBackground;
    return {
      xs: `URL(${bannerImageURL})`,
      md: `URL(${bannerImageURL})`,
    };
  }, [leftBackground]);

  const rightBG = useMemo(() => {
    const cellBackground = rightBackground.data?.attributes;
    const bannerImageURL = isFile(cellBackground) ? formatMediaUrl(cellBackground) : cellBackground;
    return {
      xs: `URL(${bannerImageURL})`,
      md: `URL(${bannerImageURL})`,
    };
  }, [rightBackground]);

  return (
    <Grid container>
      <GridItem item md={6} xs={12} sx={{ backgroundImage: leftBG, backgroundSize: leftSize }}>
        <BannerRichText content={leftText} />
        {rightLink && (
          <Button variant={'greyButton'} href={rightLink}>
            {rightLinkCopy}
          </Button>
        )}
      </GridItem>
      <GridItem item md={6} xs={12} sx={{ backgroundImage: rightBG, backgroundSize: rightSize }}>
        <BannerRichText content={rightText} />
        {leftLink && (
          <Button variant={'greyButton'} href={leftLink}>
            {leftLinkCopy}
          </Button>
        )}
      </GridItem>
    </Grid>
  );
}
